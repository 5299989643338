.header {
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 28px;
  color: #42526e;
  flex: none;
  order: 0;
  flex-grow: 0;
  max-width: 80%;
}

.item {
  padding: 4px 2px;
  font-weight: 400;
  &:hover {
    background: #fafbfc;
  }
}
