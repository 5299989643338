.linksTable {
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 400;
}

.tableHeader {
  padding-top: 20pt;
  border-bottom: 1px solid #dfe1e6;
  padding-bottom: 4pt;
}

.tableTitle {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  padding-bottom: 7pt;
}

.linkTitle {
  font-size: 14px;
  line-height: 20px;
  color: #42526e;
}

.linkUrl {
  font-size: 11px;
  line-height: 14px;
  color: #7a7e8d;
  margin-top: 0 !important;
}

.linkDate {
  font-size: 14px;
  line-height: 20px;
  color: #172b4d;
}

.linkIcon {
  padding: 7px 8px;
}

.removeLinkButton {
  cursor: pointer;
  color: #42526e;

  &:hover {
    color: #000;
  }
}
