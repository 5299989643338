.root {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  max-width: 100vw;
}

.sidePanel {
  width: 48px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 48px 10px;
  border: 1px solid #e0e6f2;
  background: #fff;
  position: fixed;
  height: 100%;
}
