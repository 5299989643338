.title {
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #7a7e8d;
  flex: none;
  order: 0;
  flex-grow: 0;
}
