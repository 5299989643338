.mentions__control {
  width: 100%;
  background-color: #fafbfc;
  padding-block: 8.5px;
  border: 2px solid #dfe1e6;
  border-radius: 3px;
}

.mentions__highlighter {
  overflow: hidden;
}

.mentions__input {
  margin-block: 1px !important;
  border: none;
  padding-block: 10.5px;
  padding-inline: 14px;
  outline-color: #749c9c;
}

.mentions__suggestions {
  width: 100%;
  margin-block-start: 39px !important;
}

.mentions__suggestions__list {
  background-color: #ffffff;
  text-align: left;
  max-height: 200px;
  overflow: auto;
}

.mentions__suggestions__item {
  padding: 5px;
}

.mentions__suggestions__item--focused {
  background-color: #dfe1e6;
}
