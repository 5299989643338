.subtitle {
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #42526e;
}

.animatedObject {
  position: relative;
  -webkit-transition: all .2s;
  -moz-transition: all .2s;
  -o-transition: all .2s;
}

.leftMoved {
  left: -63px;
}

.leftInPlace {
  left: 0;
}

.rightMoved {
  right: -63px
}

.rightInPlace {
  right: 0;
}

.hidden {
  opacity: 0;
  visibility: hidden;
}

.visible {
  opacity: 1;
  visibility: visible;
}
