.screenFrameContainer {
  position: relative;
  width: 100%;
}

.screenFrame {
  width: 100%;
  height: 100%;
}

.buttonsContainer {
  position: absolute;
  max-width: 97px;
  max-height: 50px;
  width: 97px;
  height: 50px;
  left: 0;
  right: 0;
  bottom: 7px;
  margin: auto;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  border-radius: 9px;
  opacity: 1;
  background: white;
}

.button {
  display: inline-block;
  padding-inline: 16px;
  padding-block: 17px;
}

.divider{
  display: inline-block;
  position: absolute;
  margin-block-start: 11px;
}