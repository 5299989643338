.title {
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #42526e;
  flex: none;
  order: 0;
  flex-grow: 0;
}
