.root {
  width: 240px;
  min-height: 240px;
  background: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.06);
  border-radius: 20px;
}
.root:hover {
  border: 1px solid #769e98;
  box-shadow: 0 8px 28px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
  cursor: pointer;
}

.header {
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #42526e;
}

.title {
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;

  color: #7a7e8d;
}
.value {
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  color: #42526e;
}

.piTitle {
  font-family: 'Fredoka', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #7a7e8d;
}

.piName {
  font-family: 'SF Pro Text', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  color: #7a7e8d;
}
