.headerBlock {
  background: #769e98;
  height: 36px;
}

.headerPart {
  width: 50%;
  height: 100%;
  border: 1px solid #e0e6f2;
  text-align: left;
}

.headerTitle {
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  padding-left: 24px;
}

.contentBlock {
  background: #ffffff;
  border: 1px solid #e0e6f2;
}

.contentPart {
  width: 50%;
  padding: 8px 24px 18px 24px;
  vertical-align: baseline;
}

.contentList {
}

.contentTextItem {
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #42526e;
}
