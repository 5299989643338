.background {
  background-image: url('/images/sign-in-page-background.png');
  min-width: 60%;
  background-repeat: no-repeat;
  background-size: cover;
}

.container {
  display: flex;
  min-height: 100vh;
}

.content {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 0 0 20px;
}

.logo {
  // margin: 105pt 0 180pt 0;
  flex-direction: column;
  display: flex;
  align-items: center;
}

.signInButton {
  padding: 0 80pt 0 80pt;
}

.accountMessage {
  margin: 32px 0 0;
  text-align: center;
}

.partner {
  color: #868c98;
  font-size: 12px;
  text-transform: uppercase;
  margin: 176pt 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  & img {
    margin: 20px 0 0;
    width: 49px;
    height: 35px;
  }
}
