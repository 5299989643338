.value {
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #42526e;
  flex: none;
  order: 1;
  flex-grow: 0;
}
