.main-title {
    div {
        font-family: 'Poppins', serif;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        color: #42526e;
    }
}
