.root {
  min-width: 32px;
  height: 32px;
  background: #f5f6f8;
  color: #42526e;
  box-shadow: 0 3px 5px rgba(9, 30, 66, 0.2), 0 0 1px rgba(9, 30, 66, 0.31);
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: #f1f1f1;
  }

  &:active {
    background: #e5e5e5;
  }
}

.disabled {
  @extend .root;
  color: rgba(0, 0, 0, 0.3);
  box-shadow: none;
  pointer-events: none;
}
