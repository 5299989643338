@import 'normalize.css';

@font-face {
  font-family: Poppins;
  font-display: swap;
  src: url('./fonts/Poppins-Regular.woff') format('woff');
  font-style: normal;
}

@font-face {
  font-family: Poppins;
  font-display: swap;
  src: url('./fonts/Poppins-Bold.woff') format('woff');
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: Poppins;
  font-display: swap;
  src: url('./fonts/Poppins-SemiBold.woff') format('woff');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: Inter;
  font-display: swap;
  src: url('./fonts/Inter-Regular.woff') format('woff');
  font-style: normal;
}

@font-face {
  font-family: Inter;
  font-display: swap;
  src: url('./fonts/Inter-Medium.woff') format('woff');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: Inter;
  font-display: swap;
  src: url('./fonts/Inter-Bold.woff') format('woff');
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: 'SF Pro Text';
  font-display: swap;
  src: url('./fonts/SF-Pro-Text-Regular.woff') format('woff');
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  font-display: swap;
  src: url('./fonts/SF-Pro-Text-Bold.woff') format('woff');
  font-style: normal;
  font-weight: bold;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  color: #42526e;
  background-color: #f6f8ff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
