.title {
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #42526e;
}

.button {
  height: 32px;
  background: rgba(9, 30, 66, 0.04);
  border-radius: 3px;
  border-color: rgba(9, 30, 66, 0.04);
}

.buttonText {
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.084px;
  color: #42526e;
}
