.root {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 182px;
  min-height: 60px;
  padding: 15px 7px;

  background: #ffffff;
  border: 1px solid #e0e6f2;
  border-radius: 6px;

  flex: none;
  order: 0;
  flex-grow: 0;
  cursor: pointer;
}

.rootSelected {
  @extend .root;
  border: 1px solid #2c5f57;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.trialTitle {
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #42526e;
}

.trialDetails {
  font-family: 'SF Pro Text', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #8e919f;
}
