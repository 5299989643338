.title {
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #7a7e8d;
  flex: none;
  order: 0;
  flex-grow: 0;
  max-width: 70%;
}
.fullName {
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #42526e;
  flex: none;
  order: 0;
  flex-grow: 0;
  max-width: 130px;
  word-break: break-all;
}
.email {
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #769e98;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.phone {
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #42526e;
  flex: none;
  order: 1;
  flex-grow: 0;
}
